<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form class="mt-50" @submit.prevent="handleSubmit(handleCreateItem)">
      <validation-provider
        #default="validationContext"
        name=" "
        rules="required"
      >
        <b-form-group
          :label="$t('backoffice.settings.community-name') + ' *'"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="itemInput.name[selected]"
            type="text"
            maxlength="150"
            :disabled="isSaving"
            :placeholder="$t('form-create-item.name-placeholder')"
            rules="required"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    
      <!-- IS PUBLISHED -->
      <b-form-group v-if="!fromOnboarding">
        <b-checkbox
          id="published"
          v-model="itemInput.isPublished"
          class="ml-25 zindex-0"
        >
          {{ $t("form.actions.publish") }}
        </b-checkbox>
      </b-form-group>

      <div class="float-right mb-50">
        <!-- Missing to implement BE -->
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isSaving"
          type="submit"
        >
          <span v-if="isSaving" class="d-flex">
            <b-spinner class="mr-1" small variant="white" label="Loading..." />
            {{ $t("form.actions.save") }}
          </span>
          <span v-else>
            {{ $t("form.actions.save") }}
          </span>
        </b-button>
      </div>
    </b-form>
    <error-modal
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :title="$t('appsumo.errors-modal.title')"
      :subtitle="$t('appsumo.errors-modal.subtitle')"
    />
  </validation-observer>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import { required } from "@validations";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ErrorModal from "@core/components/modal/ErrorModal.vue";

export default {
  name: "MembershipEventCreateModal",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ErrorModal,
  },
  directives: {
    Ripple,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    typeOptions: { type: Array, required: false },
    itemType: { type: String, required: true },
    canCreateOrganization: { type: Boolean, default: false },
    selected: {
      type: String,
      default: "",
    },
    typeSelected: { type: [String, Number], default: null },
    fromOnboarding: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      hasMore: false,
      itemInput: {
        slug: null,
        showCreated: true,
        isPublished: true,
        name: {},
        showPrice: 0,
      },
      formError: null,
      results: [],
      selectedTypeOption: 27413,
      placePublishOption: 0,
      required,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersError() {
      return this.$store.getters.membersError;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    // templatesData() {
    //   let templates = this.$store.getters.layoutTemplates.unpaginated;
    //   //console.log('itemInput', this.itemInput);
    //   //console.log('Templates1:', templates);
    //   if(templates?.unpaginated?.length > 0){
    //     templates = this.fetchTemplates;
    //   }
    //   //console.log('Templates2:', templates);
    //   return templates;
    // },
    isMobile() {
      return window.innerWidth < 576;
    },
    // layoutTemplates() {
    //   if (this.templatesData?.unpaginated?.length > 0) {
    //     const uniquesTemplates = this.templatesData.unpaginated.reduce((acc, current) => {
    //       const x = acc.findIndex((item) => item.key === current.key);
    //       if (x === -1) {
    //         return acc.concat([current]);
    //       }
    //       acc[x] = current;
    //       return acc;
    //     }, []);
    //     return [...this.templates, ...uniquesTemplates];
    //   }
    //   return this.templates;
    // },
    // hasAccessibility() {
    //   return !["organizations", "services"].includes(this.itemType);
    // },
    set() {
      return !["organizations"].includes(this.itemType);
    },
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  async created() {
  },
  methods: {
    async fetchData() {},
    // Create item
    async handleCreateItem() {
      this.isSaving = true;
      if (!this.itemInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        let response = {};
        const requestConfig = {
          isEvent: this.itemType === "events" ? 1 : 0,
          isProject: this.itemType === "projects" ? 1 : 0,
          isChallenge: this.itemType === "challenges" ? 1 : 0,
          isCourse: this.itemType === "courses" ? 1 : 0,
          isOrganization: this.itemType === "organizations" ? 1 : 0,
          isService: this.itemType === "services" ? 1 : 0,
          isSection: this.itemType === "sections" ? 1 : 0,
          name: this.itemInput.name,  //INPUT
          published: this.itemInput.isPublished,  //INPUT
          showCreated: this.itemInput.showCreated,
          showPrice: this.itemInput.showPrice,
          withShared: true,
          isMeeting: 0,
        };
        response = await this.$store.dispatch("createItem", {
          item: {
            itemType: "communitiesOthers",
            storedKey: this.itemType,
            requestConfig,
          },
          noSet: this.set,
        });
        this.resetForm();
        this.isSaving = false;
        this.itemInput = {
          showCreated: true,
          isPublished: true,
          name: {},
        };
        this.formError = null;
        this.$emit("create-item", true);
        this.notifySuccess(this.$t("success-message.general-success-create"));
        if(!this.fromOnboarding){
          window.location.href = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
              "{subdomain}",
              "app"
            ).replace("{slug}", response.data.slug)}/backoffice/settings`;
        }
        return;
      } catch {
        if (this.membersError !== "" && this.currentUser.appSumoID != null) {
          this.$bvModal.show("error-modal");
        } else {
          this.notifyError(this.$t("error-message.general-error"));
        }
        this.isSaving = false;
      }
    },
    resetForm() {
      this.$emit("close-modal", false);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
.wizard-tap-content {
  background-color: transparent !important;
}
</style>
